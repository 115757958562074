import styled from 'styled-components';
import React from 'react';
import TableHeader from 'src/components/molecule/TableHeader';
import { SideBar, Table } from '@veneer/core';

const InitialLoading = () => {
  const column = (id: number) => {
    return {
      id: id,
      label: '',
      width: 150,
      search: false,
      required: false,
      sortable: false,
    };
  };

  const tableColumns: Array<any> = [column(1), column(2), column(3)];

  return (
    <Wrapper>
      <StyledSidebar
        data-testid="initial-loading-sidebar"
        behavior="persistent"
        position="start"
        collapsable
      />

      <StyledTable
        data-testid="initial-loading-table"
        loading={true}
        actionArea={<TableHeader loading={true} disable={true} />}
        columns={tableColumns}
        loadingDataLength={8}
        component={'table'}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const StyledSidebar = styled(SideBar)`
  pointer-events: none;
`;

const StyledTable = styled(Table)`
  width: 100%;
  padding: 24px;
  overflow-x: hidden;
`;

export default InitialLoading;
