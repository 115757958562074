import React, { useEffect, useMemo, useState } from 'react';
import { DeviceAppConfigTemplate } from 'src/components/template';
import useTableColumn from 'src/hooks/useTableColumn';
import { setTableColumns } from 'src/utils/commonMethods';
import { deviceCacheAPI } from 'src/api/deviceCache';
import { useStoreState } from 'src/store/useStoreState';
import {
  storeDeviceTableRefresh,
  storeTableData,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';
import { useDispatch } from 'react-redux';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { useSearch } from 'src/hooks/useSearch';
import { CommonLoading } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { fullListOption } from 'src/utils/constants';
import { fetchAllwithLoop } from 'src/utils/groupMethods';

const DeviceAppConfigPage = () => {
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [enableTableRender, setEnableTableRender] = useState(false);
  const { appName } = useShellRootContext();

  const dispatch = useDispatch();
  const { getDevices } = deviceCacheAPI(appName);
  const { devicesState, devicesRnPmState } = useStoreState();
  const { deviceTableRefresh, searchItem, cfgApiRequestOptions } = devicesState;

  const tableId = 'apps-table';
  const { columnConfig } = useTableColumn();
  const { triggerSearch } = useSearch(tableId, columnConfig);
  const { tableHandler } = useTableHandler();
  const enableRnPm =
    devicesRnPmState.contentsDevicesCountRnPm && devicesRnPmState.contentsCollectionsReadRnPm;

  useEffect(() => {
    fetchForInitialTime();
    fetch();
  }, []);

  useEffect(() => {
    if (!deviceTableRefresh) {
      return;
    }
    fetch();
  }, [deviceTableRefresh]); // *NOTE: this is required, because table is refreshed when search activated

  const fetch = () => {
    setIsFetching(true);
    apiGetTableResponse()
      .then(({ devices, isError }) => {
        setTableColumns(tableId, columnConfig);
        handlePageData(devices);
        if (isError) {
          setError(isError);
        } else {
          setError(undefined);
        }
      })
      .finally(() => {
        dispatch(storeDeviceTableRefresh(false));
        setIsFetching(false);
      });
  };

  const fetchForInitialTime = () => {
    setIsFetching(true);
    getDevices({ offset: 0, limit: -1 })
      .then(({ response }) => {
        dispatch(storeTotalDeviceCountInGroup(response.length)); // to get total device count
      })
      .finally(() => {
        setIsFetching(false);
        setEnableTableRender(true);
      });
  };

  const getRequestParams = (params) => {
    let returnParams = params;
    if (params.search) {
      returnParams = { ...params, ...fullListOption };
    }
    if (cfgApiRequestOptions.search != null) {
      returnParams = { ...cfgApiRequestOptions, ...fullListOption };
    }
    return returnParams;
  };
  const apiGetTableResponse = async () => {
    console.log(`[app-overview][devices] apiGet Table Response`);

    const reqParam = getRequestParams(cfgApiRequestOptions);

    const fetch = async () => {
      const isSearch = !!cfgApiRequestOptions.search;

      if (isSearch) {
        let _response = [];
        const setAllDevices = (_data) => {
          _response = _data;
        };
        const getDevicesCallback = async (_Id, _offset, _limit) => {
          return await getDevices({
            ...reqParam,
            offset: _offset,
            limit: _limit,
          });
        };

        const devices = await fetchAllwithLoop(
          getDevicesCallback,
          setAllDevices,
          setError,
          '',
          'deviceCache',
        );
        return { response: devices };
      }

      // Regular case without search
      return await getDevices(reqParam);
    };

    const { response, error } = await fetch();

    if (error != undefined) {
      return { devices: [], isError: true };
    }

    return {
      devices: response,
      isError: false,
    };
  };

  const handlePageData = (data) => {
    let items;
    let count = data.length;

    if (data !== undefined) {
      items = JSON.parse(JSON.stringify(data));
    }

    if (searchItem) {
      const validResult = triggerSearch(items, searchItem);
      if (validResult) {
        items = validResult.pagedItems;
        count = validResult.searchedItemsCount;
        dispatch(storeTotalDeviceCountInGroup(count));
      }
    }

    const res = tableHandler.getTableData(items);
    dispatch(storeTableData(res));
  };

  const enableRender = !isFetching && enableRnPm && enableTableRender;

  const memoRenderComponent = useMemo(() => {
    if (enableTableRender && !isFetching) {
      console.log('[devices] render <DeviceforAppTableTemplate />');
      return (
        <DeviceAppConfigTemplate error={error} loading={isFetching} columnConfig={columnConfig} />
      );
    } else {
      return <></>;
    }
  }, [enableRender]);

  return <>{!enableTableRender ? <CommonLoading /> : <>{memoRenderComponent}</>}</>;
};

export default DeviceAppConfigPage;
