import React from 'react';
import { t } from 'src/utils/commonMethods';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import {
  MoveGroupModal,
  UnregisterModal,
  RemoveFromGroupModal,
  MultipleDeviceConfigModal,
} from 'src/components/organism';

const useTableModals: any = (showModal: boolean, storeShowModal: any) => {
  const { showDevicesGroupCUD, featureFlags, customRelativePath, isWex } = useShellRootContext();
  const { devicesRnPmState, devicesGroupState } = useStoreState();
  const { allDevicesGroupUuid, ungroupedGroupUuid, mainGroupSelectedId } = devicesGroupState;
  const { contentsDevicesUpdateRnPm, participantinfosDeleteRnPm, devicesDeleteRnPm } =
    devicesRnPmState;

  const getTableModals: any = () => {
    const modals = [];
    const isSolutionsAppsOverview = customRelativePath.startsWith('/solutions/app');

    if (contentsDevicesUpdateRnPm && showDevicesGroupCUD) {
      modals.push({
        component: (_props) => (
          <MoveGroupModal
            {...{ ..._props, showModal: showModal, storeShowModal: storeShowModal }}
          />
        ),
        label: t('table.move_to_group'),
        value: 'moveToGroup',
      });

      modals.push({
        component: (_props) => (
          <RemoveFromGroupModal
            {...{ ..._props, showModal: showModal, storeShowModal: storeShowModal }}
          />
        ),
        label: t('table.remove_from_group'),
        value: 'removeFromGroup',
        disabled:
          mainGroupSelectedId == allDevicesGroupUuid || mainGroupSelectedId == ungroupedGroupUuid,
      });
    }

    if (!isWex) {
      if (!isSolutionsAppsOverview) {
        modals.push({
          component: (_props) => (
            <MultipleDeviceConfigModal
              {...{ ..._props, showModal: showModal, storeShowModal: storeShowModal }}
            />
          ),
          label: t('table.configure_device'),
          value: 'multipleDeviceConfig',
        });
      }

      if (participantinfosDeleteRnPm && devicesDeleteRnPm && featureFlags.enableUnregister) {
        modals.push({
          component: (_props) => (
            <UnregisterModal {...{ ..._props, showModal, storeShowModal: storeShowModal }} />
          ),
          label: t('table.unregister'),
          value: 'unregister',
        });
      }
    }

    return modals;
  };

  return {
    getTableModals,
  };
};

export default useTableModals;
