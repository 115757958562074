import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useEffect, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { Modal, Button } from '@veneer/core';
import { collectionAPI } from 'src/api/collection';
import { useStoreState } from 'src/store/useStoreState';
import { MoveGroupTree } from 'src/components/molecule';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { storeSelectedItems } from 'src/store/devices/action';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import {
  storeMoveGroupModalSelectedId,
  storeMoveGroupModalSelectedName,
} from 'src/store/devicesGroup/action';

type TMoveGroupModalProps = {
  showModal: boolean;
  storeShowModal: (e: boolean) => void;
  setIsLoadingMfe: (isLoading: boolean) => void;
};

const MoveGroupModal = (props: TMoveGroupModalProps) => {
  const { devicesGroupState, devicesRnPmState } = useStoreState();
  const { contentsDevicesCountRnPm, contentsCollectionsReadRnPm } = devicesRnPmState;
  const { refreshPage, refreshGroup, refreshDevicesTable } = useRefreshHook();
  const { patchTypedCollectionById } = collectionAPI();
  const {
    allDevicesGroupUuid,
    ungroupedGroupUuid,
    mainGroupSelectedId,
    moveGroupModalSelectedId: selectedGroupId,
    moveGroupModalSelectedName: selectedGroupName,
    mainGroupData,
  } = devicesGroupState;
  const { useToast, isWex } = useShellRootContext();
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const { devicesState } = useStoreState();
  const { selectedItems } = devicesState;

  useEffect(() => {
    props.setIsLoadingMfe(false);
  }, []);

  const MoveDevicesToGroup = async (targetGroupId) => {
    //Get device id from selected device list
    const selectedDeviceIds = selectedItems.map((data) => {
      return {
        deviceId: data.deviceId,
        groupId: data.groups[0] == undefined ? ungroupedGroupUuid : data.groups[0].uuid,
      };
    });

    if (mainGroupSelectedId == allDevicesGroupUuid) {
      //Remove devices from prev groups when selected group is 'All Device'
      //Get each device's previous group id using device id
      const selectedDevicesGroup = {};
      for (const selectedDevice of selectedDeviceIds) {
        if (selectedDevicesGroup[selectedDevice.groupId] === undefined)
          selectedDevicesGroup[selectedDevice.groupId] = [{ deviceId: selectedDevice.deviceId }];
        else {
          selectedDevicesGroup[selectedDevice.groupId].push({
            deviceId: selectedDevice.deviceId,
          });
        }
      }

      for (const [originalGroupId, selectedDevicesInGroup] of Object.entries(
        selectedDevicesGroup,
      )) {
        if (
          originalGroupId != allDevicesGroupUuid &&
          originalGroupId != ungroupedGroupUuid &&
          originalGroupId != targetGroupId
        ) {
          //EditGroupCreate device list to remove
          let selectedDevices = [];
          if (Array.isArray(selectedDevicesInGroup)) {
            selectedDevices = selectedDevicesInGroup.map((device) => {
              return device.deviceId;
            });
          }

          const { error } = await patchTypedCollectionById(
            originalGroupId,
            'devices',
            [],
            selectedDevices,
          );
          if (error) {
            useToast.addToast({
              id: 'retry',
              type: 'negative',
              text: t('table.move_fail_msg'),
            });
            refreshPage();
            return;
          }
        }
      }
    } else {
      //remove device from prev group when selected group is specific group
      if (mainGroupSelectedId != ungroupedGroupUuid) {
        const { error } = await patchTypedCollectionById(
          mainGroupSelectedId,
          'devices',
          [],
          selectedDeviceIds.map((selectedDevice) => {
            return selectedDevice.deviceId;
          }),
        );
        if (error) {
          useToast.addToast({
            id: 'retry',
            type: 'negative',
            text: t('table.move_fail_msg'),
          });
          refreshPage();
          return;
        }
      }
    }

    //Add devices to target group
    const { response, error } = await patchTypedCollectionById(
      targetGroupId,
      'devices',
      selectedDeviceIds.map((selectedDevice) => {
        return selectedDevice.deviceId;
      }),
      [],
    );
    if (response) {
      useToast.addToast({
        id: 'success',
        type: 'positive',
        text: t('table.move_success_msg'),
      });
    }
    if (error) {
      useToast.addToast({
        id: 'retry',
        type: 'negative',
        text: t('table.move_fail_msg'),
      });
    }
    refreshGroup();
  };

  return (
    <Modal
      show={props.showModal}
      title=""
      maxWidth="612px"
      alignFooter={isWex ? undefined : 'end'}
      footer={
        <Footer isWex={isWex}>
          <Button
            onClick={() => {
              if (selectedGroupId && selectedGroupName) {
                setIsFetching(true);
                MoveDevicesToGroup(selectedGroupId).finally(() => {
                  setIsFetching(false);
                  dispatch(storeSelectedItems([]));
                  refreshDevicesTable();
                  props.storeShowModal(false);
                });
              }
            }}
            appearance="primary"
            loading={isFetching}
            data-testid="move-to-group-modal-action-button"
          >
            {t('table.move')}
          </Button>

          <Button
            onClick={() => {
              props.storeShowModal(false);
              dispatch(storeMoveGroupModalSelectedId(''));
              dispatch(storeMoveGroupModalSelectedName(''));
            }}
            appearance="secondary"
            disabled={isFetching}
            data-testid="move-to-group-modal-cancel-button"
          >
            {t('table.cancel')}
          </Button>
        </Footer>
      }
    >
      <Title data-testid="move-to-group-modal-title">{t('table.move_to_group')}</Title>
      <Content data-testid="move-to-group-modal-desc">{t('table.move_description')}</Content>

      <MoveGroupTree
        groups={mainGroupData}
        useToast={useToast}
        ungroupedGroupUuid={ungroupedGroupUuid}
        allDevicesGroupUuid={allDevicesGroupUuid}
        contentsDevicesCountRnPm={contentsDevicesCountRnPm}
        contentsCollectionsReadRnPm={contentsCollectionsReadRnPm}
      />
    </Modal>
  );
};

const Footer = styled.div.attrs(() => {
  return { className: 'vn-button-group--responsive' };
})`
  display: flex;
  width: 100%;

  button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;
const Title = styled.div`
  font-family: var(--fontFamilyRegular);
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight6};
  vertical-align: middle !important;
  min-height: 36px;
`;

const Content = styled.div`
    font-family: var(--fontFamilyRegular);
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight3}
    min-height: 24px;
    margin : 16px 0;
`;

export default MoveGroupModal;
