import React, { useMemo } from 'react';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { SubMfeDeviceDetailsConfig } from 'src/components/molecule';
import { useRefreshHook } from 'src/hooks/useRefreshHook';

type TMultipleDeviceConfigModalProps = {
  showModal: boolean;
  storeShowModal: (e: boolean) => void;
  setIsLoadingMfe: (isLoading: boolean) => void;
};

const MultipleDeviceConfigModal = (props: TMultipleDeviceConfigModalProps) => {
  const { useToast } = useShellRootContext();
  const { devicesState } = useStoreState();
  const { selectedItems } = devicesState;
  const { refreshPage } = useRefreshHook();

  const handleUseToast = (obj) => useToast.addToast(obj);

  const handleSetShowModal = (flag) => {
    props.storeShowModal(flag);
    if (!flag) {
      refreshPage();
    }
  };

  const getSelectedDeviceIdList = () => {
    return selectedItems.map((item) => {
      return item.deviceId;
    });
  };

  const render = useMemo(() => {
    return (
      <SubMfeDeviceDetailsConfig
        data-testid="multiple-config-modal"
        {...props}
        {...{
          handleUseToast,
          selectedDeviceIdList: getSelectedDeviceIdList(),
          selectedData: selectedItems,
          showModal: props.showModal,
          setShowModal: handleSetShowModal,
        }}
      />
    );
  }, [props.showModal]);

  return <>{render}</>;
};

export default MultipleDeviceConfigModal;
