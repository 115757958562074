import { t } from 'src/utils/commonMethods';

export const getGroups = async (
  getCollectionsReturn: (offset, limit, name?) => Promise<{ response: any; error: any }>,
  setGroups: (data) => void,
  setErrorForGroups?: (data) => void,
  setTotalCountForGroups?: (data) => void,
) => {
  let totalGroups = [];
  const limit = -1;
  const { response, error } = await getCollectionsReturn(0, limit, undefined);
  if (error != undefined) {
    if (setErrorForGroups != undefined) {
      setErrorForGroups(error);
    }
    return;
  }
  totalGroups = [...response.contents];
  if (response.totalSize > response.contents.length) {
    for (let offset = limit - 1; offset <= response.totalSize; offset = offset + limit) {
      const { response: additionalResponse, error: additionalError } = await getCollectionsReturn(
        0,
        limit,
        undefined,
      );
      if (additionalError == true) {
        if (setErrorForGroups != undefined) {
          setErrorForGroups(additionalError);
        }
        return;
      }
      totalGroups = [...totalGroups, ...additionalResponse.contents];
    }
  }
  const allDevicesGroupUuid = setGroups(totalGroups);
  if (setTotalCountForGroups != undefined) setTotalCountForGroups(totalGroups.length);

  return allDevicesGroupUuid;
};

export const getLokalisedGroupLabel = (name) => {
  if (name == 'All') {
    return t('group.group_all');
  }
  if (name == 'Ungrouped') {
    return t('group.group_ungrouped');
  }
  return name;
};

export const getTreeViewParentNodes = (
  groupData,
  allDevicesGroupUuid,
  ungroupedGroupUuid,
  disableLabel = false,
) => {
  const groupTree = [
    {
      id: (disableLabel ? 'disable_edit-group-label__' : '') + allDevicesGroupUuid,
      label: getLokalisedGroupLabel('All'),
      totalChildren: 0,
      nodes: [],
    },
  ];

  // NOTE: Current spec is to only have 'All' group as a sole parent node.
  groupData.forEach((group) => {
    if (group.id == allDevicesGroupUuid) {
      groupTree[0].totalChildren = group.devices;
      return;
    }
    if (group.id == ungroupedGroupUuid) {
      groupTree[0].nodes.unshift({
        id: (disableLabel ? 'disable_edit-group-label__' : '') + group.id,
        label: getLokalisedGroupLabel(group.name),
        totalChildren: group.devices,
      });
      return;
    }
    groupTree[0].nodes.push({
      id: group.id,
      label: getLokalisedGroupLabel(group.name),
      totalChildren: group.devices,
    });
  });

  return groupTree;
};

export const fetchAllwithLoop = async (getAPI, setData, setError, groupId, apiType?: string) => {
  const limit = apiType == 'deviceCache' ? 200 : 500;
  let offset = 0;
  const totalList = [];

  const filterItemFormat = (res) => {
    if (apiType == 'collection') {
      return res?.contents ?? [];
    }
    return res;
  };

  try {
    let hasMore = false;
    do {
      const { response, error } = await getAPI(groupId, offset++, limit);
      const items = filterItemFormat(response);

      if (error) {
        throw new Error(error);
      }
      if (items && items.length > 0) {
        totalList.push(...items);
        hasMore = items.length === limit;
      } else {
        hasMore = false;
      }
    } while (hasMore);
  } catch (error) {
    setError(true);
  }
  setData(totalList);
  return totalList;
};
