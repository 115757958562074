import React, { useState, useEffect, useMemo, useRef } from 'react';
import useTableColumn from 'src/hooks/useTableColumn';
import { useSearch } from 'src/hooks/useSearch';
import { fetchAllwithLoop, getGroups } from 'src/utils/groupMethods';
import { useDispatch } from 'react-redux';
import { fleetMgtAPI } from 'src/api/fleetMgt';
import { collectionAPI } from 'src/api/collection';
import { useStoreState } from 'src/store/useStoreState';
import { InitialLoading } from 'src/components/molecule';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { DeviceMainTemplate } from 'src/components/template';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { fullListOption, solutionID } from 'src/utils/constants';
import { getProps, getTableId, setTableColumns } from 'src/utils/commonMethods';
import {
  storeTableData,
  storeDeviceTableRefresh,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';
import {
  storeMainGroupData,
  storeMainGroupRefresh,
  storeUngroupedGroupUuid,
  storeAllDevicesGroupUuid,
  storeMainGroupSelectedId,
} from 'src/store/devicesGroup/action';

type TDeviceMainPageProps = {
  setShowEditGroupModal: (boolean) => void;
};

const DeviceMainPage = (props: TDeviceMainPageProps) => {
  const { customRelativePath, isWex } = useShellRootContext();
  const { devicesState, devicesGroupState, devicesRnPmState } = useStoreState();

  const { contentsDevicesCountRnPm, contentsCollectionsReadRnPm } = devicesRnPmState;
  const { mainGroupSelectedId, mainGroupRefresh } = devicesGroupState;
  const { deviceTableRefresh, searchItem, cfgApiRequestOptions } = devicesState;

  // api related methods
  const { getCollections, getTypedCollectionById } = collectionAPI();
  const { getCompliance } = fleetMgtAPI();
  const dispatch = useDispatch();

  // table related variables * DO NOT CREATE DEVICE COUNT IN REDUX *
  const tableId = getTableId(customRelativePath);

  const { columnConfig } = useTableColumn();
  const { triggerSearch } = useSearch(tableId, columnConfig);
  const { tableHandler } = useTableHandler();
  const enableRnPm = contentsDevicesCountRnPm && contentsCollectionsReadRnPm;
  const serviceID = getProps().serviceID;

  // fetch related flags
  //TODO: Need to inject as props all the way down to Devices Table
  // setApiRequestOption: change of search key value, pageSize, page number, sort, filter
  // tableRefresh: Anywhere that needs refresh
  const [isFetchingGroup, setIsFetchingGroup] = useState(true);
  const [isFetchingTable, setIsFetchingTable] = useState(true);
  const [errorGroup, setErrorGroup] = useState(undefined);
  const [errorTable, setErrorTable] = useState(undefined);
  const [enableRenderTable, setEnableRenderTable] = useState(false);
  const complianceList = useRef([]);

  useEffect(() => {
    apiGetGroups()
      .then((allGroupId) => apiGetCompliance(allGroupId).then())
      .finally(() => {
        setIsFetchingGroup(false);
      });
  }, []);

  useEffect(() => {
    if (errorGroup !== undefined || errorTable !== undefined) {
      setIsFetchingGroup(false);
      setIsFetchingTable(false);
      dispatch(storeAllDevicesGroupUuid(''));
      dispatch(storeUngroupedGroupUuid(''));
    }
  }, [errorGroup, errorTable]);

  useEffect(() => {
    if (isFetchingGroup == false && errorGroup == undefined) {
      setIsFetchingTable(true);
      setEnableRenderTable(false);

      apiGetTableResponse(mainGroupSelectedId)
        .then(({ devices, isError }) => {
          setTableColumns(tableId, columnConfig);
          handlePageData(devices);
          if (isError) {
            setErrorTable(isError);
          } else {
            setErrorTable(undefined);
          }
        })
        .finally(() => {
          setIsFetchingTable(false);
        });
    }
  }, [isFetchingGroup]);

  useEffect(() => {
    if (mainGroupRefresh) {
      apiGetGroups()
        .then((allGroupId) => apiGetCompliance(allGroupId).then())
        .finally(() => {
          dispatch(storeMainGroupRefresh(false));
        });
    }
  }, [mainGroupRefresh]);

  useEffect(() => {
    if (!deviceTableRefresh) {
      return;
    }

    apiGetTableResponse(mainGroupSelectedId)
      .then(({ devices, isError }) => {
        setTableColumns(tableId, columnConfig);
        handlePageData(devices);
        if (isError) {
          setErrorTable(isError);
        } else {
          setErrorTable(undefined);
        }
      })
      .finally(() => {
        dispatch(storeDeviceTableRefresh(false));
      });
  }, [deviceTableRefresh]);

  useEffect(() => {
    let completeGetTable = false;
    let completeGetGroup = false;

    completeGetGroup = isFetchingGroup == false && (errorGroup == undefined || isWex);
    completeGetTable = isFetchingTable == false && (errorTable == undefined || isWex);

    setEnableRenderTable(completeGetGroup && completeGetTable);
  }, [isFetchingGroup, isFetchingTable]);

  const handlePageData = (data) => {
    let items;
    if (data !== undefined) {
      items = JSON.parse(JSON.stringify(data));
    }

    // Insert complianceList into items
    items = items?.map((item) => ({
      ...item,
      complianceList: complianceList.current.find((comp) => comp.deviceId === item.deviceId) || {},
    }));

    if (searchItem) {
      const validResult = triggerSearch(items, searchItem);
      if (validResult) {
        items = validResult.pagedItems;
        dispatch(storeTotalDeviceCountInGroup(validResult.searchedItemsCount));
      }
    }

    const res = tableHandler.getTableData(items);
    dispatch(storeTableData(res));
  };

  const getRequestParams = (params) => {
    let newParams = params;

    if (serviceID !== undefined && params.hasOwnProperty('filter')) {
      delete newParams.filter;
    }
    if (serviceID == solutionID.secureFleetManger) {
      newParams = { ...params, filter: serviceID };
    }
    if (params.search) {
      newParams = { ...params, ...fullListOption };
    }

    if (cfgApiRequestOptions.search) {
      newParams = { ...cfgApiRequestOptions, ...fullListOption };
    }

    return newParams;
  };

  // To get group data
  const apiGetGroups = async () => {
    console.log('[devices] apiGetGroups');
    return await getGroups(getCollections, setGroups, setErrorGroup);
  };

  const apiGetCompliance = async (allGroupId) => {
    console.log('[devices] apiGetCompliance');
    const setCompliance = (response) => {
      complianceList.current = response;
    };

    return await fetchAllwithLoop(getCompliance, setCompliance, setErrorTable, allGroupId);
  };

  const setGroups = (newGroups) => {
    const allDevicesGroupObj = newGroups.filter((group) => group.name === 'All')[0];
    const ungroupedGroupObj = newGroups.filter((group) => group.name === 'Ungrouped')[0];

    const allDevicesGroupUuid = allDevicesGroupObj.id;
    const ungroupedGroupUuid = ungroupedGroupObj.id;
    const totalDeviceCountInGroup = allDevicesGroupObj.devices ?? 0;

    dispatch(storeMainGroupSelectedId(allDevicesGroupUuid));
    dispatch(storeAllDevicesGroupUuid(allDevicesGroupUuid));
    dispatch(storeUngroupedGroupUuid(ungroupedGroupUuid));
    dispatch(storeMainGroupData(newGroups));
    dispatch(storeTotalDeviceCountInGroup(totalDeviceCountInGroup));

    return allDevicesGroupUuid;
  };

  const apiGetTableResponse = async (_mainGroupSelectedId) => {
    console.log(`[devices] apiGet Table Response`);

    const reqParam = getRequestParams(cfgApiRequestOptions);
    const fetch = async () => {
      const isSearch = !!cfgApiRequestOptions.search;

      if (isSearch) {
        let _response = [];
        const setAllDevices = (_data) => {
          _response = _data;
        };
        const geTypedCollectionAPI = async (_Id, _offset, _limit) => {
          return await getTypedCollectionById(_Id, {
            ...reqParam,
            offset: _offset,
            limit: _limit,
          });
        };

        const devices = await fetchAllwithLoop(
          geTypedCollectionAPI,
          setAllDevices,
          setErrorTable,
          _mainGroupSelectedId,
          'collection',
        );
        return { response: { contents: devices } };
      }

      // Regular case without search
      return await getTypedCollectionById(_mainGroupSelectedId, reqParam);
    };

    const { response, error } = await fetch();

    if (error != undefined) {
      return { devices: [], isError: true };
    }

    return {
      devices: response?.contents,
      isError: false,
    };
  };

  const memoRenderComponent = useMemo(() => {
    if ((!isFetchingGroup && !isFetchingTable && isWex) || (enableRenderTable && enableRnPm)) {
      console.log('[devices] render <DeviceMainTemplate />');
      return (
        <DeviceMainTemplate
          errorTable={errorTable}
          loadingTable={isFetchingTable}
          errorGroup={errorGroup}
          columnConfig={columnConfig}
          setShowEditGroupModal={props.setShowEditGroupModal}
        />
      );
    } else {
      return <></>;
    }
  }, [enableRenderTable]);

  return (
    <>{isFetchingGroup || isFetchingTable ? <InitialLoading /> : <>{memoRenderComponent}</>}</>
  );
};

export default DeviceMainPage;
