import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import TableHeader from 'src/components/molecule/TableHeader';
import TableEmptyMsg from 'src/components/atom/TableEmptyMsg';
import { Table, TableI18n } from '@veneer/core';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { storeInitialColumns } from 'src/store/devices/action';
import { getTableId, jsonParse, t } from 'src/utils/commonMethods';
import { defaultPageSizeOptions, preferences } from 'src/utils/constants';
import { PaginationInterface } from '@veneer/core/dist/scripts/pagination/types';

type TDeviceTableProps = {
  error: boolean;
  loading: boolean;
  tableData: any;
  deviceCount: number;
  showCheckbox: any;
  showHeaderActionArea: any;
  showExportAll: any;
  columnConfig: any;
  columnReorder?: boolean;
};

const DeviceTable = (props: TDeviceTableProps) => {
  const dispatch = useDispatch();
  const { tableHandler } = useTableHandler();
  const { devicesState } = useStoreState();
  const { currentPage, pageSize, selectedItems, searchItem, cfgApiRequestOptions, columnContents } =
    devicesState;
  const { customRelativePath, isWex, isFromApps } = useShellRootContext();
  const tableId = getTableId(customRelativePath);
  const disableSearchBox = props.deviceCount == 0 && searchItem == '';

  const [emptyState, setEmptyState] = useState('');
  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(tableId), props.columnConfig.cfgInitialColumnsList()),
  );
  const i18nPagination = {
    currentPage: t('table.current_page'),
    goto: t('table.go_to_page'),
    next: t('table.next'),
    paginationNavigation: t('table.pagination_navigation'),
    pageSizeInformation: t('table.page_size_info'),
    previous: t('table.previous'),
    selectPageSize: t('table.select_page_size'),
  };

  useEffect(() => {
    if (props.error) {
      setEmptyState('error');
      return;
    }
    if (props.tableData.length == 0 && cfgApiRequestOptions.search) {
      setEmptyState('search_empty');
      return;
    }
    if (props.tableData.length == 0) {
      setEmptyState('no_device');
    }
  }, [props.error, props.tableData]);

  /**<CAUTION> 'defaultOrder' and 'columns' MUST BE SAME to enable disabled 'Reset to Default' button, else doesn't work
   - order : count of checked items in [Column Options] modal. It decides columns presented at initial Table
   - defaultOrder : columns array
   ex) ['A', 'B', 'C'] when clicked 'Reset to Default' button in [Column Options] modal
   - columns : columns presented in [Columns Options] Modal.
   ex) [{id: 'A'}, {id: 'B'}, {id: 'C'}]
   */
  const getPreferences = () => {
    return {
      ...preferences,
      defaultOrder: props.columnConfig.cfgInitialColumnsList(),
      order: order,
      width: props.columnConfig.cfgColumnsList().map((col) => {
        return {
          columnId: col.id,
          width: col.width,
        };
      }),
    };
  };

  const getActionArea = () => {
    if (props.showHeaderActionArea) {
      const disableCondition = props.error || props.loading || disableSearchBox;

      return (
        <TableHeader
          disable={disableCondition}
          showExportAll={props.showExportAll}
          selectedItems={selectedItems}
          columnConfig={props.columnConfig}
          columnContents={columnContents}
        />
      );
    } else {
      return <></>;
    }
  };

  const getPagination = (): PaginationInterface => {
    const defaultEmptyPagination: PaginationInterface = {
      currentPage: 0,
      i18n: i18nPagination,
      onPageChange: tableHandler.handlePageChange,
      onPageSizeChange: tableHandler.handlePageSizeChange,
      pageSize: 1,
      pageSizeOptions: defaultPageSizeOptions,
      popoverPlacement: 'top-start',
      totalItems: props.deviceCount ?? 0,
    };

    return { ...defaultEmptyPagination, currentPage: currentPage, pageSize: pageSize };
  };

  const getRetrieveI18nItems = (): Partial<TableI18n> => {
    return {
      cancelButton: t('table.cancel'),
      columnOptions: t('table.column_options'),
      resetToDefault: t('table.reset_to_default'),
      saveButton: t('table.save'),

      actionButton: t('table.select_an_action'),
      clearAllFilters: t('table.clear_filters'),
      columnResizeTooltip: t('table.resize_tool_tip'),
      deselectAllItems: t('table.deselect_all'),
      downButton: t('table.down_button'),
      itemSelected: t('table.item_selected'),
      itemsSelected: t('table.items_selected'),
      noItems: t('table.no_items'),

      selectAllItems: t('table.select_items'),
      selectAllPageItems: t('table.select_page_items'),
      sortedAscending: t('table.sorted_ascending'),
      sortedDescending: t('table.sorted_descending'),
      upButton: t('table.up_button'),
    };
  };

  const getSelectionState = () => {
    const total = props.tableData.length;
    const selected = props.tableData.filter((row) => row.rowConfig.selected).length;

    switch (selected) {
      case 0:
        return 'none';
      case total:
        return 'all';
      default:
        return 'indeterminated';
    }
  };

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder);
    sessionStorage.setItem(tableId, JSON.stringify(newOrder));
    dispatch(storeInitialColumns(newOrder));
  };

  return (
    <Wrapper
      id={`${tableId}-section`}
      isWex={isWex}
      data-testid={`${tableId}-section`}
      className={'dui-scrollbar'} //for wx scrollbar
      isFromApps={isFromApps}
    >
      <Table
        loading={props.loading}
        data-testid={tableId}
        actionArea={getActionArea()}
        columnReorder={(props.columnReorder && !props.loading) ?? true}
        onColumnReorder={handleOnColumnReorder}
        component={'table'}
        customNoItems={<TableEmptyMsg type={emptyState} />}
        columns={props.columnConfig.cfgColumnsList()}
        data={props.tableData}
        onSort={tableHandler.handleSort}
        preferences={getPreferences()}
        pagination={props.deviceCount != 0 ? getPagination() : undefined}
        onSelect={tableHandler.handleSelect}
        onSelectAllPageItems={tableHandler.handleSelectAllPageItems}
        rowSelectAllState={getSelectionState()}
        rowSelector={props.showCheckbox}
        i18n={getRetrieveI18nItems() as TableI18n}
        numberOfSelectedItems={undefined}
      />
    </Wrapper>
  );
};

export default DeviceTable;

const Wrapper = styled.div`
  width: 100%;
  overflow-x: ${(props) => !props.isFromApps && 'hidden'};
  padding: 24px;

  ${(props) => {
    if (props.isWex) {
      return 'height: calc(100vh - 152px);';
    }
    //breadcrumb(80px) + header(108px) + tab(48px) + footer(64px) + padding(48px) space between(32px)
    else return 'height: calc(100vh - 236px - 64px - 48px - 32px);';
  }}
  > div > div:nth-child(2)::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  > div > div:nth-child(2)::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  > div > div:nth-child(2)::-webkit-scrollbar-thumb {
    background: #888;
  }

  > div > div:nth-child(3) > div > div > button {
    height: 32px;
  }

  > div > div {
    height: auto;
    width: auto;
  }

  ${(props) => {
    if (props.isFromApps) {
      return css`
        padding: 0;
        height: inherit;

        > div {
          height: inherit;
        }

        > div > div:nth-child(2) {
          max-height: calc(100% - 48px - 30px);
          width: 101%;
        }
      `;
    }
  }}
`;
