import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React from 'react';
import { t } from 'src/utils/commonMethods';

type TTableConnectivityRendererProps = {
  value: string;
  textComponent: React.ComponentElement<any, any>;
};

const TableConnectivityRenderer = (props: TTableConnectivityRendererProps) => {
  return (
    <Wrapper>
      <ConnectivityIcon data-testid={'connectivity-renderer-icon'} connectionState={props.value} />
      <Text>{props.textComponent}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const ConnectivityIcon = styled.div`
  position: absolute;
  width: 12px;
  height: 13px;
  background-color: ${(props) => {
    switch (props.connectionState) {
      case t('table.online'):
        return tokens.color.green6;
      case t('table.offline'):
      default:
        return tokens.color.red6;
    }
  }};
  margin-right: 8px;
  display: inline-block;
  border-radius: 100%;
`;

const Text = styled.div`
  margin-left: 19px;
`;

export default TableConnectivityRenderer;
