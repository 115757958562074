import styled from 'styled-components';
import React from 'react';
import { IconCheckmarkCircle, IconInfo, IconMinusCircle, IconWarningAlt } from '@veneer/core';

type TTableStatusRendererProps = {
  value: string;
  textComponent: React.ComponentElement<any, any>;
};

const TableStatusRenderer = (props: TTableStatusRendererProps) => {
  const getStatusIcon = () => {
    switch (props.value) {
      case 'UNKNOWN':
        return <IconInfo color="gray6" filled={true} size={20} />;
      case 'ERROR':
        return <IconMinusCircle color="red7" filled={true} size={20} />;
      case 'WARNING':
        return <IconWarningAlt color="darkOrange6" filled={true} size={20} />;
      case 'READY':
        return <IconCheckmarkCircle color="hunterGreen7" filled={true} size={20} />;
    }
  };

  return (
    <Wrapper data-testid={'status-renderer'}>
      {getStatusIcon()}
      <StatusText>{props.textComponent}</StatusText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusText = styled.div`
  margin-left: 5px;
`;

export default TableStatusRenderer;
