import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { getMockUpData, getProps, isMockUpMode } from 'src/utils/commonMethods';
import {
  refinedGetDeviceGroups,
  refinedGetDeviceGroupsAll,
  refinedGetDeviceGroupsUngrouped,
  refinedUseGetTypedCollectionById,
} from 'src/api/collection/collectionRefinery';

export const collectionAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.collection);
  const serviceID = getProps().serviceID;

  const getCollections = async (offset, limit, name?) => {
    let response, error, refinedData;

    if (isMockUpMode()) {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await getMockUpData(`collection/get-collections`).then((data) => {
        response = refinedGetDeviceGroups(data);
      });

      return { response, error };
    }

    await client
      .getCollections(
        offset,
        limit,
        undefined,
        name ?? undefined,
        undefined,
        undefined,
        undefined,
        true,
        serviceID,
      )
      .then((res) => {
        switch (name) {
          case 'All':
            refinedData = refinedGetDeviceGroupsAll(res.data);
            break;
          case 'Ungrouped':
            refinedData = refinedGetDeviceGroupsUngrouped(res.data);
            break;
          default:
            refinedData = refinedGetDeviceGroups(res.data);
        }
        response = refinedData;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const createCollection = async (collectionName, deviceIdList) => {
    let response, error;

    if (isMockUpMode()) {
      return { response, error };
    }

    await client
      .createCollection({
        data: {
          name: collectionName,
          visibility: 'visible',
          events: {
            devices: ['all'],
            users: ['all'],
            collections: ['all'],
          },
          devices: deviceIdList,
          users: null,
          collections: null,
        },
      })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const patchCollectionById = async (collectionId, collectionName) => {
    let response, error;

    await client
      .patchCollectionById(collectionId, {
        data: {
          name: collectionName,
        },
      })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const deleteCollectionByIds = async (collectionIds) => {
    let response, error;

    for (let i = 0; i < collectionIds.length; i++) {
      await client
        .deleteCollectionById(collectionIds[i])
        .then((res) => {
          response = res.data;
        })
        .catch((err) => {
          error = err;
        });
    }

    return { response, error };
  };

  const getTypedCollectionById = async (collectionId, options) => {
    let response, error;

    if (isMockUpMode()) {
      await getMockUpData('collection/get-typed-collection-by-id').then((data) => {
        response = refinedUseGetTypedCollectionById(data);
      });
      return { response, error };
    }

    await client
      .getTypedCollectionById(
        collectionId,
        'devices',
        options.offset,
        options.limit,
        options.sortBy,
        serviceID,
        options.recursiveExpansion,
      )
      .then((res) => {
        response = refinedUseGetTypedCollectionById(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const patchTypedCollectionById = async (collectionId, entityType, addList, removeList) => {
    if (addList == null) addList = [];
    if (removeList == null) removeList = [];

    let response, error;
    if (isMockUpMode()) {
      return { response: { data: '200 ok' }, error: undefined };
    }

    await client
      .patchTypedCollectionById(collectionId, entityType, {
        data: {
          add: addList,
          remove: removeList,
        },
      })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getCollections,
    createCollection,
    patchCollectionById,
    deleteCollectionByIds,
    getTypedCollectionById,
    patchTypedCollectionById,
  };
};
